export default [
  {
    id: 1,
    name: "English",
  },
  {
    id: 2,
    name: "Russian",
  },
  {
    id: 3,
    name: "Uzbek",
  },
  {
    id: 4,
    name: "Tajik",
  },
];
