export default [
  {
    id: 1,
    name: "Traveling",
  },
  {
    id: 2,
    name: "Teaching",
  },
  {
    id: 3,
    name: "Trading Stocks",
  },
  {
    id: 4,
    name: "Cryptocurrency/Blockchain",
  },
];
